/* You can add global styles to this file, and also import other style files */
  /* @import '../src/app/custom.css';
  @import '../src/app/style.css';
 @import '../src/assets/wp-content/et-cache/et-core-unified-1.css';
 @import '../src/assets/wp-content/et-cache/et-divi-customizer-global.css';
 @import '../src/assets/wp-content/themes/Divi/style8603.css';
 @import '../src/assets/wp-content/et-cache/et-core-unified-5.css';
 @import '../src/assets/wp-content/plugins/contact-form-7/includes/css/stylesb62d.css';
 @import '../src/assets/wp-includes/css/dist/block-library/customestyles.css';
 @import '../src/assets/wp-includes/css/dashicons.css';
 @import '../src/assets/wp-includes/js/mediaelement/mediaelementplayer-legacy.css';
 @import '../src/assets/wp-includes/js/mediaelement/wp-mediaelement.css'; 
  */
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
  body {
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif !important;
  }

